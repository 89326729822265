import { Grid } from "@mui/material"
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useSelector } from "react-redux";
import UploadInfoText from "./Text/UploadInfoText";
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AlertTitle from '@mui/material/AlertTitle';
import LinearBuffer from './ProgressBar'
import {useState, useEffect} from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const UploadIcon = () => {

    const [showProgress, setShowProgress] = useState(true);
    const [hideProgress, setHideProgress] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowProgress(false);
        setHideProgress(true)
      }, 2000); // 2 seconds
      return () => clearTimeout(timer);
    }, []);

    const linkedPineFolder = useSelector(
        (state: any) => state.updatePineData.linkedPineFolder
      );
    const olfoldercheck = useSelector(
      (state: any) => state.updatePineData.olfoldercheck
    );

    const theme = createTheme({
      palette: {
        primary: {
          main: '#9C007C',
        },
      },
    });
    
    return (
        <Grid
            item
            xs={12}
            sx={{
              borderRadius: "10px",
              padding: "2%",
              backgroundColor: "white",
            }}
          >
            <UploadInfoText/>
            <Grid container direction="row" sx={{ fontSize: "12px" }}>
              <Grid item data-testid = 'UploadIcon'>
                <FolderOpenIcon fontSize="small" />
              </Grid>
              <Grid
                item
                sx={{ padding: "0.4%", marginLeft: "1%", color: "#006684" }}
              >
                {linkedPineFolder}
              </Grid>
            </Grid>
            {showProgress && (
              <LinearBuffer/>
            )}

            {hideProgress && (
              olfoldercheck ?
                <Alert variant="outlined" severity="success" iconMapping={{success: <CheckCircleOutlineIcon fontSize="inherit" />}}>
                <AlertTitle>File Path Verified in Pine</AlertTitle>
                    This filename and location has been verified in Pine.
                </Alert>
              :
              <ThemeProvider theme={theme}>
                <Alert variant="outlined" severity="error" sx={{ borderColor: '#9C007C' }} iconMapping={{success: <CheckCircleOutlineIcon fontSize="inherit" sx={{ color: '#9C007C' }} />}} >
                <AlertTitle sx={{ borderColor: '#9C007C' }} >File Path Does Not Exist in Pine</AlertTitle>
                    Should you proceed, a new file path will be created.
                </Alert>
              </ThemeProvider>
              
            )}
            
          </Grid>
    )
}

export default UploadIcon