import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import DoneIcon from "@mui/icons-material/Done"
import ErrorIcon from "@mui/icons-material/Error"
import CancelIcon from "@mui/icons-material/Cancel"
import AddLinkIcon from "@mui/icons-material/AddLink"
import { useDispatch, useSelector } from "react-redux"
import { apiEndpoint } from "../../../constants/appConstant"
import { beginUploadButtonStyle } from "../../../styles/sxStyle"
import { Grid, TextField, Typography, Button, InputAdornment, IconButton } from "@mui/material"
import { updatePineDataActions } from "../../../store/uploadPineSlice"
import { getCurrentYearAndMonth } from "../../../utility/commonFunction"

const FolderValidation = (props: any): JSX.Element => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [validName, setValidName] = useState(true)
    const pineFolderLocation = useSelector((state: any) => state?.updatePineData?.pineFolderLocation)
    const selectedLOBValue = useSelector((state: any) => state?.updatePineData?.selectedLOBValue)
    const navigateToNextPage = () => navigate("/pine/addSingleFile")
    const getPineFolderLocation = () => {
        if (pineFolderLocation?.includes(selectedLOBValue))
            return pineFolderLocation;
        else {
            let finalSelectedPineFolder = `${selectedLOBValue}/${pineFolderLocation}`
            return finalSelectedPineFolder;
        }
    }

    const getFinalSelectedPineFolderLocation = () => {
        let initialSelectedLocation: any = getPineFolderLocation()
        if (initialSelectedLocation === `${selectedLOBValue}/`) {
            let finalStr: any = `${initialSelectedLocation}${getCurrentYearAndMonth()}`
            dispatch(updatePineDataActions.setPineFolderLocation(`${finalStr}`))
            dispatch(updatePineDataActions.setLinkedPineFolder(`${finalStr}`))
            return finalStr;
        } else {
            return initialSelectedLocation;
        }
    }

    const linkClickHandler = () => {
        const finalReg = /^[a-zA-Z0-9_ -]+(?:\/[a-zA-Z0-9_ -]+(?:\s+[a-zA-Z0-9_ -]+)*)*$/;
        const checkName = finalReg.test(getFinalSelectedPineFolderLocation());
        if (pineFolderLocation?.includes(selectedLOBValue))
            dispatch(updatePineDataActions.setLinkedPineFolder(`${pineFolderLocation}`))
        else {
            dispatch(updatePineDataActions.setPineFolderLocation(`${selectedLOBValue}/${pineFolderLocation}`));
            dispatch(updatePineDataActions.setLinkedPineFolder(`${selectedLOBValue}/${pineFolderLocation}`))
        }
        if (checkName) {

            let config = {
                method: "get",
                url: `${apiEndpoint}/checkOlFolder?folderLocation=${getFinalSelectedPineFolderLocation()}`,
            }

            axios(config)
            .then(function (response) {
                //console.log("response folder link", response)
                if (response.data.status) {
                    props.updateOlfoldercheck(true)
                    dispatch(updatePineDataActions.setFolderAvaliableInOl(true))
                } else {
                    props.updateOlfoldercheck(false)
                    dispatch(updatePineDataActions.setFolderAvaliableInOl(false))
                }
                
                props.setShowChecked(true)
                navigateToNextPage()
            })
            .catch(function (error) {
                //console.log("err occured : ", error.status)
                props.updateOlfoldercheck(false)
                dispatch(updatePineDataActions.setFolderAvaliableInOl(false))
            })
        }
        else {
            setValidName(false)
            return;
        }
    }

    return (
        <>
            <Grid item xs={12} sx={{ marginTop: "1%" }} data-testid="FolderValidation">
                <TextField fullWidth autoComplete="off" id="filled-basic" label="Pine Folder Location" variant="filled"
                    error={!validName ? true : false}
                    sx={{
                        "& label.Mui-focused": {
                            color: "#40484C",
                        },
                    }}
                    value={pineFolderLocation}
                    onChange={(event) => {
                        dispatch(updatePineDataActions.setPineFolderLocation(event?.target?.value));
                        setValidName(true);
                    }}

                    InputProps={{
                        sx: { padding: "0" },
                        endAdornment: pineFolderLocation && (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        dispatch(updatePineDataActions.setPineFolderLocation(`${selectedLOBValue}/`));
                                        props.setShowChecked(false)
                                    }}
                                >
                                    {validName ? (<CancelIcon sx={{ color: "black" }} />) : (<ErrorIcon sx={{ color: "#CB0000" }} />)}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                {validName === false && (
                    <Typography sx={{ fontSize: "10px", color: "#CB0000" }}>
                        This is not a valid path
                    </Typography>
                )}

            </Grid>

            <Grid item container direction="row" justifyContent="flex-end" sx={{ marginTop: "2%" }}>
                {props.showChecked ? (
                    <Grid sx={{ display: "flex", flexDirection: "row", color: "#006684" }}>
                        <DoneIcon />
                        {"Linked"}
                    </Grid>
                ) : (
                    <Button variant="contained" size="small" sx={beginUploadButtonStyle}
                        onClick={() => { linkClickHandler() }}
                        style={{ paddingRight: "15px", paddingLeft: "15px" }}
                    >
                        <AddLinkIcon fontSize="small" sx={{ paddingRight: "10px" }} />{" "}
                        <Typography sx={{ textTransform: "capitalize", fontWeight: "bold" }}>
                            Link to pine
                        </Typography>
                    </Button>
                )}
            </Grid>
        </>
    )
}
export default FolderValidation
